import get from 'lodash-es/get';
import { v4 as uuidv4 } from 'uuid';
import { GwDatesWrapper } from '../../../components/_internal_date_/gwDatesWrapper';
import { getLocation } from '../../../components/utilities/ResortLocations';
import {
  DATE_FORMATS,
  DAY_PASSES_ROOM_TYPE,
  EVENT_CATEGORIES,
  EVENT_PARAMS,
  GTM_EVENT,
  PRODUCT_SCOPES,
  RECOMMENDED_FOR_YOU
} from '../../../utilities/constants';
import { getReservationCurrency } from '../../../utilities/reservationsUtils';
import { SessionStorageKeys, getSpecificItem } from '../../../utilities/storageUtils';
import { getPaymentDescription } from '../analytics/analyticsUtils';
import { getPackageCategory, getPackageQuantity, getPkgType, isRecommendedPackage } from './analyticsUtils';

// Full enhanced E-Commerce Object
export const buildPurchaseEnhancedECommerceObject = (
  { reservation, products, revenue, tax, shipping, voyagerPointsDiscount, offersPointsDiscount, dayType, UUID },
  eventCategory = EVENT_CATEGORIES.GTM.transaction,
  productScope = PRODUCT_SCOPES.suiteCheckout
) => {
  const uniquePackages = [...new Map(products.map(m => [m.id, m])).values()];
  uniquePackages.forEach(unique => {
    unique.dimension63 = getPaymentDescription(reservation);
  });

  return {
    event: eventCategory,
    resortLocation: getLocation(reservation.property).url, //custom dimension for resort location that we can easily filter EE events by,
    purchaseLocation: productScope, //custom dimension for transaction location
    ecommerce: {
      currencyCode: getReservationCurrency(reservation.selection.site),
      purchase: {
        actionField: {
          id: UUID ? UUID : uuidv4(),
          confirmationNumber: reservation.externalId ? reservation.externalId : reservation.id,
          affiliation: GTM_EVENT.LABEL.greatWolfLowerCase,
          revenue: revenue,
          shipping: shipping,
          tax: tax,
          coupon: reservation.rateCode,
          voyagerPointsDiscount: voyagerPointsDiscount,
          offersPointsDiscount: offersPointsDiscount,
          SIP: dayType
        },
        products: uniquePackages
      }
    }
  };
};

// Enhanced E-Commerce experience for suite
export const buildSuitePurchaseEnhancedECommerceObject = (
  suite,
  reservation,
  productScope = PRODUCT_SCOPES.suiteCheckout,
  maxOccupancy,
  suiteCategory
) => {
  const checkInDate = get(reservation, 'arrival', '');
  const checkoutDate = get(reservation, 'departure', '');

  return {
    name: reservation.roomDescription.split('-')[0].trim() || reservation.roomType,
    id: reservation.roomType,
    price: reservation.rateInfo.totalRoomRate,
    brand: 'great wolf',
    category: 'suites',
    variant: suiteCategory, // standard, themed, premium, condo, cabin
    quantity: 1,
    coupon: reservation.offerCode,
    dimension29: getLocation(reservation.property).url, // product scope dimension for resort
    dimension30: 'suites', // product scope dimension for category
    dimension31: reservation.selection.numberOfNights, // product scope dimension for number of nights
    dimension32: reservation.selection.numAdults, // product scope dimension for number of adults
    dimension33: reservation.selection.numChildren, // product scope dimension for number of kids
    dimension34: productScope, // product scope dimension for add to cart location (pre vs post transaction)
    dimension35: GwDatesWrapper.format(checkInDate, DATE_FORMATS.default),
    dimension36: GwDatesWrapper.format(checkoutDate, DATE_FORMATS.default),
    dimension61: Number(maxOccupancy) || null, // product scope dimension for Maximum Suite Occupancy
    dimension62: reservation?.children || [] // product scope dimension for Kids Ages
  };
};

// New enhances E-Commerce experience for packages
export const buildPackagesPurchaseEnhancedECommerceObject = (
  {
    addedPackagesCodes,
    packages,
    groupedAddedPackages,
    reservation,
    packagesContent,
    formatedOffers = [],
    maxOccupancy
  },
  productScope = PRODUCT_SCOPES.postPurchase
) => {
  const checkInDate = get(reservation, 'arrival', '');
  const checkoutDate = get(reservation, 'departure', '');
  const addedPackagesFormat = addedPackagesCodes.reduce((acc, packageCode) => {
    const packagesByCode = packages.reduce((keysAcc, currentPackage) => {
      keysAcc[currentPackage.packageCode] = currentPackage;
      return keysAcc;
    }, {});

    const pkgType = getPkgType(packagesContent, packageCode);

    acc.push({
      name: packagesByCode[packageCode].title || packagesByCode[packageCode].description,
      id: packageCode,
      price: groupedAddedPackages[packageCode].reduce((revenueAcc, packageItem) => {
        return revenueAcc + packageItem.unitAmount;
      }, 0),
      brand: GTM_EVENT.LABEL.greatWolfLowerCase,
      category: 'packages',
      variant: pkgType,
      quantity: packageCode.includes('PARKING')
        ? reservation.selection.numberOfNights
        : packagesByCode[packageCode].quantity,
      coupon: reservation.offerCode,
      dimension29: getLocation(reservation.property).url, //product scope dimension for resort
      dimension30: 'packages', //product scope dimension for category
      dimension31: reservation.selection.numberOfNights, //product scope dimension for number of nights
      dimension32: reservation.selection.numAdults, //product scope dimension for number of adults
      dimension33: reservation.selection.numChildren, //product scope dimension for number of kids
      dimension34: productScope, //product scope dimension for add to cart location (pre vs post transaction)
      dimension35: GwDatesWrapper.format(checkInDate, DATE_FORMATS.default),
      dimension36: GwDatesWrapper.format(checkoutDate, DATE_FORMATS.default),
      dimension61: Number(maxOccupancy) || null, // product scope dimension for Maximum Suite Occupancy
      dimension62: reservation.children || [] // product scope dimension for Kids Ages
    });
    const uniquePackages = [...new Map(acc.map(m => [m.id, m])).values()];
    return uniquePackages;
  }, []);

  if (formatedOffers.length > 0) {
    formatedOffers.forEach(offer => {
      addedPackagesFormat.push({
        name: offer.packageName,
        id: offer.packageCode,
        price: -offer.amount,
        brand: GTM_EVENT.LABEL.greatWolfLowerCase,
        category: offer.category,
        variant: offer.packageCategory,
        quantity: offer.quantity,
        coupon: reservation.offerCode,
        dimension29: getLocation(reservation.property).url, //product scope dimension for resort
        dimension30: 'packages', //product scope dimension for category
        dimension31: reservation.selection.numberOfNights, //product scope dimension for number of nights
        dimension32: reservation.selection.numAdults, //product scope dimension for number of adults
        dimension33: reservation.selection.numChildren, //product scope dimension for number of kids
        dimension34: productScope, //product scope dimension for add to cart location (pre vs post transaction)
        dimension35: GwDatesWrapper.format(checkInDate, DATE_FORMATS.default),
        dimension36: GwDatesWrapper.format(checkoutDate, DATE_FORMATS.default)
      });
    });

    return addedPackagesFormat;
  }

  return addedPackagesFormat;
};

export const buildImpressionsEventObject = (resortLocation, eventProps, purchaseLocation) => ({
  event: PRODUCT_SCOPES.productImpressions,
  resortLocation: resortLocation,
  purchaseLocation: purchaseLocation || null,
  ecommerce: {
    currencyCode: 'USD',
    impressions: Array.isArray(eventProps) ? eventProps : [eventProps]
  }
});

export const buildImpressionsDataObject = (
  kidsAges,
  suite,
  resortLocation,
  checkoutDate,
  checkInDate,
  adultsCount,
  kidsCount,
  currentPosition,
  customPurchaseLocation = null
) => ({
  name: suite.title,
  id: suite.suiteCode,
  price: suite.totalRate,
  brand: suite.suiteBrand,
  category: 'suites',
  variant: suite.suiteCategory,
  list: 'suites',
  position: currentPosition,
  dimension27: suite.flags && suite.flags[RECOMMENDED_FOR_YOU] ? 'recommended' : '',
  dimension28: '',
  dimension29: resortLocation,
  dimension30: 'suites',
  dimension31: GwDatesWrapper.diff(checkoutDate, checkInDate, 'days'),
  dimension32: adultsCount,
  dimension33: kidsCount,
  dimension34: customPurchaseLocation ? customPurchaseLocation : 'suite-checkout',
  dimension35: GwDatesWrapper.format(checkInDate, DATE_FORMATS.default),
  dimension36: GwDatesWrapper.format(checkoutDate, DATE_FORMATS.default),
  dimension61: Number(suite?.selectedSuite?.maxOccupancy) || null, // product scope dimension for Maximum Suite Occupancy
  dimension62: kidsAges || [] // product scope dimension for Kids Ages
});

export const extraGuestPurchaseObj = (reservation, maxOccupancy, suiteCategory, amount, additionalGuests) => {
  const nights = GwDatesWrapper.diff(reservation?.departure, reservation?.arrival, 'days');

  return {
    name: reservation?.roomDescription.split('-')[0],
    id: reservation?.roomType,
    price: amount.toFixed(2),
    brand: 'great wolf',
    category: 'suites',
    variant: suiteCategory,
    quantity: 1,
    dimension27: 'recommended',
    dimension28: 'recommended',
    dimension29: reservation?.property,
    dimension30: 'suites',
    dimension31: nights.toString() + (nights > 1 || nights === 0 ? ' nights' : nights > 0 && ' night'),
    dimension32: !additionalGuests.adultsCount
      ? '0 adults'
      : additionalGuests.adultsCount.toString() +
        (additionalGuests.adultsCount > 1 ? ' adults' : additionalGuests.adultsCount === 1 && ' adult'),
    dimension33: !additionalGuests.childrenAges
      ? '0 kids'
      : additionalGuests.childrenAges.length.toString() +
        (additionalGuests.childrenAges.length > 1 ? ' kids' : additionalGuests.childrenAges.length === 1 && ' kid'),
    dimension34: 'modify reservation',
    dimension35: GwDatesWrapper.format(reservation?.departure, DATE_FORMATS.default),
    dimension36: GwDatesWrapper.format(reservation?.arrival, DATE_FORMATS.default),
    dimension61: Number(maxOccupancy), // product scope dimension for Maximum Suite Occupancy
    dimension62: !additionalGuests.childrenAges ? [] : additionalGuests.childrenAges // product scope dimension for Kids Ages
  };
};

export const buildImpressionsDataObjectPkgs = (
  pkg,
  resortLocation,
  checkoutDate,
  checkInDate,
  adultsCount,
  kidsCount,
  currentPosition,
  purchaseLocation
) => {
  return {
    name: pkg.title,
    id: pkg.packageCode,
    price: pkg.amount,
    brand: 'great wolf',
    category: 'package',
    variant: pkg.packageType ? pkg.packageType[0]?.trim() : '',
    list: 'suites',
    position: currentPosition,
    dimension27: pkg.flags && pkg.flags[RECOMMENDED_FOR_YOU] ? 'recommended' : '',
    dimension28: '',
    dimension29: resortLocation,
    dimension30: 'suites',
    dimension31: GwDatesWrapper.diff(checkoutDate, checkInDate, 'days'),
    dimension32: adultsCount,
    dimension33: kidsCount,
    dimension34: purchaseLocation || 'suite-checkout',
    dimension35: GwDatesWrapper.format(checkInDate, DATE_FORMATS.default),
    dimension36: GwDatesWrapper.format(checkoutDate, DATE_FORMATS.default)
  };
};

export const getDayPassBaseObject = reservation => {
  return {
    city: reservation.address.city,
    region: reservation.address.stateProvince,
    item_code: reservation.roomType,
    item_group: 'WPF',
    room_type: DAY_PASSES_ROOM_TYPE[reservation.roomType] || '',
    room_name: reservation.selection.roomTypeDescription,
    value: reservation.rateInfo.totalRoomRate
  };
};

export const getBaseSuiteObject = (reservation, locationDisplayName, locationState, suiteCategory) => {
  return {
    checkout_date: GwDatesWrapper.format(reservation.departure, DATE_FORMATS.default),
    num_children: reservation.selection.numChildrenOverTwo,
    num_infants: reservation.selection.numChildrenUnderTwo,
    children_ages: reservation.children,
    city: locationDisplayName,
    region: locationState,
    room_nights: reservation.selection.numberOfNights,
    promo_code: reservation.offerCode,
    room_type: suiteCategory,
    room_name: reservation.selection.roomTypeDescription.split(' - ')[0]
  };
};

export const getEventBaseObject = (event, reservation, currencyCode) => {
  return {
    [EVENT_PARAMS.fbEventId]: uuidv4(),
    event,
    transaction_id: reservation.id,
    checkin_date: GwDatesWrapper.format(reservation.arrival, DATE_FORMATS.default),
    num_travelers: reservation.adults + reservation.children.length,
    num_adults: reservation.selection.numAdults,
    value: reservation.rateInfo.totalRoomRate,
    average_room_rate: (reservation.rateInfo.totalRoomRate / reservation.selection.numberOfNights).toFixed(2),
    currency: currencyCode,
    country: reservation.address.country
  };
};

export const buildProductClickEnhancedECommerceObject = (
  kidsAges,
  suite,
  resortLocation,
  checkoutDate,
  checkinDate,
  adultsCount,
  kidsCount,
  currentPosition,
  purchaseLocation
) => {
  return {
    event: PRODUCT_SCOPES.productClick,
    resortLocation: resortLocation,
    ecommerce: {
      currencyCode: 'USD',
      click: {
        actionField: {
          list: 'suites'
        },
        products: [
          {
            ...buildProductEnhancedECommerce(
              suite,
              resortLocation,
              checkoutDate,
              checkinDate,
              adultsCount,
              kidsCount,
              suite.avgNightlyRate,
              purchaseLocation
            ),
            position: currentPosition
          }
        ]
      }
    }
  };
};

export const buildAddToCartEnhancedEcommerceObject = (
  eventType,
  kidsAges,
  suite,
  resortLocation,
  checkoutDate,
  checkinDate,
  adultsCount,
  kidsCount,
  currentPosition,
  purchaseLocation
) => {
  return {
    event: eventType,
    resortLocation: resortLocation,
    purchaseLocation,
    ecommerce: {
      currencyCode: 'USD',
      [eventType === EVENT_CATEGORIES.GTM.addToCart ? 'add' : 'remove']: {
        actionField: {
          list: 'suites',
          position: currentPosition || '',
          coupon: suite.rateCode ?? ''
        },
        products: [
          {
            ...buildProductEnhancedECommerce(
              suite,
              resortLocation,
              checkoutDate,
              checkinDate,
              adultsCount,
              kidsCount,
              suite.totalRate,
              purchaseLocation,
              kidsAges
            ),
            quantity: 1
          }
        ]
      }
    }
  };
};

export const buildSearchObject = (
  resortLocation,
  searchType,
  bonusDealValue,
  searchLocation,
  checkinDate,
  checkoutDate,
  adultsCount,
  kidsCount,
  kidAges,
  discountCode
) => {
  return {
    event: EVENT_CATEGORIES.GTM.search,
    resortLocation: resortLocation,
    searchType: searchType,
    bonus_deal_value: bonusDealValue,
    searchLocation: searchLocation,
    arrival_date: checkinDate,
    departure_date: checkoutDate,
    numberOfAdults: adultsCount,
    numberOfKids: kidsCount,
    kidAges: kidAges,
    discountCode: discountCode,
    NumNights: GwDatesWrapper.diff(checkoutDate, checkinDate, 'days')
  };
};

const buildProductEnhancedECommerce = (
  suite,
  resortLocation,
  checkoutDate,
  checkinDate,
  adultsCount,
  kidsCount,
  price,
  purchaseLocation,
  kidsAges
) => {
  const nights = GwDatesWrapper.diff(checkoutDate, checkinDate, 'days');
  return {
    name: suite.title,
    id: suite.suiteCode,
    price: price,
    brand: 'great wolf',
    category: 'suites',
    variant: suite.suiteCategory,
    dimension27: suite?.flags && suite?.flags.includes(RECOMMENDED_FOR_YOU) ? 'recommended' : '',
    dimension28: '',
    dimension29: resortLocation,
    dimension30: 'suites',
    dimension31: nights,
    dimension32: adultsCount,
    dimension33: kidsCount,
    dimension34: purchaseLocation || 'suite-checkout',
    dimension35: GwDatesWrapper.format(checkinDate, DATE_FORMATS.default),
    dimension36: GwDatesWrapper.format(checkoutDate, DATE_FORMATS.default),
    dimension61: Number(suite?.maxOccupancy) || null, // product scope dimension for Maximum Suite Occupancy
    dimension62: kidsAges || [], // product scope dimension for Kids Ages
    coupon: suite.rateCode ?? ''
  };
};

export const getSignInEventObject = (action, label) => {
  if (!action || !label) return;
  return {
    event: GTM_EVENT.NAME.websiteVitals,
    category: EVENT_CATEGORIES.GTM.signIn,
    action: action,
    label: label
  };
};

export const getSignUpEventObject = (action, label, isLeadGenSignUp) => {
  if (!action || !label) return;
  const eventObject = {
    event: GTM_EVENT.NAME.websiteVitals,
    category: EVENT_CATEGORIES.GTM.signUp,
    action: action,
    label: label
  };
  if (label === GTM_EVENT.LABEL.google || label === GTM_EVENT.LABEL.greatWolf)
    return {
      ...eventObject,
      sign_up_location: isLeadGenSignUp ? 'subscribe' : 'sign up'
    };
  else return eventObject;
};

export const getLeadGenEventObject = (action, label) => {
  if (!action || !label) return;
  return {
    event: GTM_EVENT.NAME.websiteVitals,
    category: EVENT_CATEGORIES.GTM.leadgen,
    action: action,
    label: label
  };
};

export const getPasswordChangeEventObject = (action, label) => {
  if (!action || !label) return;
  return {
    event: GTM_EVENT.NAME.websiteVitals,
    category: EVENT_CATEGORIES.GTM.passwordChange,
    action: action,
    label: label
  };
};

export const getRaFSignUpEventObject = () => {
  if (sessionStorage.getItem(SessionStorageKeys.REFER_A_FRIEND)) {
    const MemberId = getSpecificItem('MemberId', 'referFriend');

    if (typeof MemberId === 'string' && MemberId) {
      const lst = MemberId.slice(-1);
      const action = +lst % 2 === 0 ? GTM_EVENT.ACTION.signUpAttemptRaF50 : GTM_EVENT.ACTION.signUpAttemptRaF25;
      return getSignUpEventObject(action, GTM_EVENT.LABEL.greatWolf);
    }
  }
};

/**
 * Build enhanced ecommerce object for Checkout steps
 * @param {Object} suite Suite redux object
 * @param {Object} guests Guest redux object
 * @param {Object} dates Dates redux object
 * @param {Object} resortLocation Resort location redux object
 * @param {number} step Current step at checkout page
 * @param {boolean} isUserLoggedIn User status
 * @param {string} option Payment type
 * @returns Object
 */
export const buildCheckoutEventObject = (suite, guests, dates, resortLocation, step, isUserLoggedIn, option = '') => {
  const { adultsCount, kidsCount, kidsAges } = guests;
  const { checkinDateSelection: checkinDate, checkoutDateSelection: checkoutDate } = dates;
  return {
    event: EVENT_CATEGORIES.GTM.checkoutStep,
    resortLocation: resortLocation,
    loggedinStatus: isUserLoggedIn,
    ecommerce: {
      currencyCode: 'USD',
      checkout: {
        actionField: {
          step,
          option
        },
        products: [
          {
            ...buildProductEnhancedECommerce(
              suite,
              resortLocation,
              checkoutDate,
              checkinDate,
              adultsCount,
              kidsCount,
              suite.totalRate,
              undefined,
              kidsAges
            ),
            quantity: 1
          }
        ]
      }
    }
  };
};

/**
 * Build enhanced ecommerce object for add/removal cart
 * @param {string} eventType Event type one of: add-to-cart, remove-from-cart
 * @param {Object} eventPackage Object of the new or removed package
 * @param {Object} packagesTable Packages planPackages redux object -- packagesTableJson
 * @param {Object} guests Guest redux object
 * @param {Object} dates Dates redux object
 * @param {Object} resortLocation Resort location redux object
 * @param {string} purchaseLocation Location of the event on the Booking Flow
 * @returns Object
 */
export const buildPackageCartEventObject = (
  eventType,
  eventPackage,
  packagesTable,
  guests,
  dates,
  resortLocation,
  qtyDiff = null,
  purchaseLocation = 'standard purchase',
  currentPathLocation = '',
  suite = [],
  isCartOpenClose
) => {
  const { adultsCount, kidsCount, kidsAges } = guests;
  const { checkinDateSelection: checkinDate, checkoutDateSelection: checkoutDate } = dates;
  const numberOfNights = checkoutDate !== undefined && GwDatesWrapper.diff(checkoutDate, checkinDate);

  // Get Products
  const products = eventPackage.map(pkg => {
    let { packageName, packageCode, amount, category, packageCategory, quantity } = pkg;
    // Check if is recommended
    const isRecommended = packagesTable && eventPackage ? isRecommendedPackage(eventPackage, packagesTable) : false;
    // Get package type
    packageCategory = packageCategory ? packageCategory : getPackageCategory(pkg, packagesTable)?.toLowerCase();

    // Get quanity -- for dining by package we should to perform one operation
    quantity = quantity
      ? quantity
      : qtyDiff
      ? qtyDiff
      : getPackageQuantity(pkg, packageCategory, guests, numberOfNights);

    category = category ? category : 'packages';

    return {
      name: packageName,
      id: packageCode,
      price: amount, // the nightly rate. what the user sees on the room card
      brand: 'great wolf', // static
      category: category, // static
      variant: packageCategory, // one of: attractions, family, birthday, dining, lco, flextrip
      quantity: quantity,
      dimension27: '', // empty string
      dimension28: isRecommended ? 'recommended' : '', // if the recommended package, otherwise empty string
      dimension29: resortLocation,
      dimension30: 'packages', // static
      dimension31: numberOfNights, // number of nights
      dimension32: adultsCount, // number of adults
      dimension33: kidsCount, // number of kids
      dimension34: isCartOpenClose
        ? 'standard purchase - cart'
        : purchaseLocation
        ? purchaseLocation
        : currentPathLocation, // static
      dimension35: GwDatesWrapper.format(checkinDate, DATE_FORMATS.default), // arrival date
      dimension36: GwDatesWrapper.format(checkoutDate, DATE_FORMATS.default),
      dimension61: Number(suite?.selectedSuite?.maxOccupancy) || null, // product scope dimension for Maximum Suite Occupancy
      dimension62: kidsAges || [], // product scope dimension for Kids Ages
      coupon: suite?.selectedSuite?.rateCode ?? ''
    };
  });

  //const { packageCode, packageName, amount } = eventPackage;

  return {
    event: eventType, // one of: add-to-cart, remove-from-cart
    resortLocation: resortLocation,
    purchaseLocation: isCartOpenClose ? 'standard purchase - cart' : purchaseLocation,
    ecommerce: {
      currencyCode: 'USD',
      [eventType === EVENT_CATEGORIES.GTM.addToCart ? 'add' : 'remove']: {
        actionField: {
          list: 'packages',
          position: '',
          coupon: suite?.selectedSuite?.rateCode ?? ''
        },
        products: products
      }
    }
  };
};

export const rateCalendarGA = (action, label) => {
  if (!action || !label) return;
  return {
    event: GTM_EVENT.NAME.websiteVitals,
    category: EVENT_CATEGORIES.GTM.rateCalendar,
    action: action,
    label: label
  };
};

export const OFFERS_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove'
};

export const offerEventBuilder = (action, offerInfo) => {
  return {
    event: `bonus_deal_click_${action}`,
    resortLocation: offerInfo.resortLocation, //custom dimension for resort location that we can easily filter EE events by
    bonus_deal_name: offerInfo.name, //ex. July Bonus,
    bonus_deal_value: offerInfo.value //ex. 50 points,
  };
};

/**
 * Build enhanced ecommerce object for reservation cancellation
 * @param {Object} eventProps Object with relevant data for event
 * @param {Object} resortLocation Resort location redux object
 * @param {string} cancellationData Data related to the cancellation response
 * @returns Object
 */
export const buildCancelReservationEventObject = (
  eventProps,
  resortLocation,
  cancellationData,
  purchaseLocation = 'standard purchase'
) => {
  const {
    adultsCount,
    kidsCount,
    kidsAges,
    suiteName,
    suiteCode,
    rateCode,
    maxOccupancy,
    roomTaxes,
    checkinDate,
    checkoutDate,
    paymentType
  } = eventProps;
  const numberOfNights = checkoutDate !== undefined && GwDatesWrapper.diff(checkoutDate, checkinDate);

  const { depositPaidAmount, totalRefundAmount, cancelNumber } = cancellationData;

  const products = [
    {
      name: suiteName,
      id: suiteCode,
      price: depositPaidAmount, // the nightly rate. what the user sees on the room card
      brand: 'great wolf', // static
      category: 'suites', // static
      variant: 'standard', // one of: attractions, family, birthday, dining, lco, flextrip
      quantity: 1,
      coupon: rateCode ?? '',
      dimension31: numberOfNights, // number of nights
      dimension32: adultsCount, // number of adults
      dimension33: kidsCount, // number of kids
      dimension34: purchaseLocation, // static
      dimension35: GwDatesWrapper.format(checkinDate, DATE_FORMATS.default), // arrival date
      dimension36: GwDatesWrapper.format(checkoutDate, DATE_FORMATS.default),
      dimension61: maxOccupancy || null, // product scope dimension for Maximum Suite Occupancy
      dimension62: kidsAges || [], // product scope dimension for Kids Ages
      dimension63: paymentType,
      dimension68: totalRefundAmount
    }
  ];

  return {
    event: 'refund', // one of: add-to-cart, remove-from-cart
    resortLocation: resortLocation,
    purchaseLocation: purchaseLocation,
    ecommerce: {
      currencyCode: 'USD',
      purchase: {
        actionField: {
          id: uuidv4(),
          affiliation: 'great wolf',
          revenue: depositPaidAmount,
          shipping: '0.00',
          tax: roomTaxes,
          coupon: rateCode,
          voyagerPointsDiscount: 0,
          offersPointsDiscount: 0,
          SIP: 2,
          confirmationNumber: cancelNumber,
          refundAmount: -Number(totalRefundAmount ?? 0)
        },
        products: products
      }
    }
  };
};
